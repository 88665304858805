   // ********************************************************
// * Extend Foundation Typography with brand-specific needs
// *
// ********************************************************
$default-h1-to-h4-font-weight: 700 !default;
$default-h5-to-h6-font-weight: $default-h1-to-h4-font-weight;

@mixin vans-typography {

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4
    {
        text-transform: uppercase;
        font-weight: $default-h1-to-h4-font-weight;
    }
    h5,
    .h5,
    h6,
    .h6 {
        text-transform: uppercase;
        font-weight: $default-h5-to-h6-font-weight;        
    }

    hr {
        max-width: none;
    }
}
